/**
 * Render a dataset.
 * Supported props: studies [theme topic refloc range]
 */
import React from 'react';
import Study from './Study';
import locSorter from '../util/geotools';
import { sortByField } from '../util/datatools';
/*
import usePager from './usePager';
import PagerNav from '../components/PagerNav';
*/

export default function ResultsPanel(props) {
  const rootAttribs = {id: 'results-panel'};
  if (props.theme) rootAttribs.className = props.theme;
  if (!Array.isArray(props.studies)) return <div  {...rootAttribs}>Studies not configured</div>;
  // const pager = usePager(props.studies, 2);

  const topic = (props.topic ? (props.topic.label || '') : '');
  const topicId = (props.topic ? (props.topic.value || '') : '');
  const refLoc = props.refloc;
  let studies = props.studies;
  const proximity = Number(props.range) || 0;
  if (refLoc) {
    // Assign a 'closest' field for sorting
    studies = props.studies.map(study => Object.assign(study, {closest: locSorter(refLoc, study.locations)[0].dist}));
    sortByField(studies, 'closest');
    if (proximity>0) studies = studies.filter(study => study.closest <= proximity);
  }

  return (
    <div {...rootAttribs}>
      <div className="r-title">Found {studies.length} {topic} trials</div>
      <div className="r-descrip">
      A listing of {topic} medical research trials actively recruiting patient volunteers. Search by city to find more detailed information on a research study in your area.
      </div>
      {studies.map((study, index) => <Study key={`st-${index}`} config={study} refloc={refLoc} range={props.range} />)}
    </div>
  );
};

/* Render study pages with pager
      <div className="mt-12">
        {pager.page.map((study, index) => <Study key={`st-${index}`} config={study} refloc={refLoc} range={props.range} />)}
        <PagerNav pager={pager} />
      </div>
*/

/**
 * Render a check box.
 * Supported props: name label onChange theme doClear supp
 */
import React from 'react';
import { checkboxGroup } from '../styles/checkbox.module.scss';

export default function Checkbox(props) {
  const [confirmed, setConfirmed] = React.useState(false);

  function handleChange(e) {
    const currvalue = !confirmed;
    if (props.onChange) props.onChange(currvalue, e.target.name, props.label);
    setConfirmed(currvalue);
  }

  const attribs = {
    id: `ck-${props.name}-${props.label}`,
    type: 'checkbox',
    name: props.name,
    value: props.label,
    checked: confirmed,
    onChange: handleChange
  };
  if (props.theme) attribs.className = props.theme;

  React.useEffect(() => { if (props.doClear) setConfirmed(false); });

  return (
    <div id={`container-${props.name}-${props.label}`} className={checkboxGroup}>
      <div className="fg-1">
        <label htmlFor={attribs.id}><input {...attribs} /><span className="pl-20">{props.label}</span></label>
      </div>
      <div className="text-pary pr-20">{props.supp}</div>
    </div>
  );
};

/**
 * Render field filters by which a dataset may be filtered.
 * Supported props: topic dataset onFilter theme
 */
import React, { useState, useEffect } from 'react';
import Checkbox from './Checkbox';
import useDatafilter from './useDatafilter';
import {css} from '../util/pagetools';
import { findDistinct, filterData } from '../util/datatools';

export default function DatasetFilter(props) {
  if (!Array.isArray(props.dataset)) return null;

  const rootAttribs = {id: 'dataset-filter'};
  if (props.theme) rootAttribs.className = props.theme;
  const [clear, setClear] = useState(false);
  const df = useDatafilter();
  const distinctValues = findDistinct(props.dataset, df.getFields());
  const fieldLabels = {
    ageGroup: 'Age group'
  };

  function refreshData(clearFilter) {
    const subset = filterData(props.dataset, (clearFilter ? null : df.getCriteria()), props.topic);
    if (props.onFilter) props.onFilter(subset);
  }

  function setFilter(checked, field, value) {
    if (checked) setClear(false);
    else if (df.isEmpty()) setClear(true);
    df.onPick(checked, field, value);
    refreshData();
  }

  function clearFilter() {
    setClear(true);
    df.clear();
    refreshData(true);
  }

  useEffect(() => { refreshData(); }, [props.topic]);

  return (
    <div {...rootAttribs}>
      <div className="flx">
        <div className="fg-1">Filters:</div>
      </div>
      {distinctValues.map((item, index) => (
        <div key={`d-${index}`}>
          <div className="cap1 pb-8">{fieldLabels[item.name] || item.name}</div>
          <div className="plain-list">
            {item.values.map((value, valueIndex) => (
            <li key={`li-${valueIndex}`}>
              <Checkbox name={item.name} label={value} onChange={setFilter} doClear={clear} supp={item.counts[value]} />
            </li>
            ))}
          </div>
        </div>
      ))}
      <div className="flx-ctr">
        <button className={css('noborder btn-white-on-light', (clear ? 'hid': (df.isEmpty()?'hid':'vis')))} onClick={clearFilter}>Reset Filters</button>
      </div>
    </div>
  );
};

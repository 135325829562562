/**
 * Render a dataset and a filtering component next to it.
 * Supported props: studies [conditions]
 */
import React, { useState } from 'react';
import ResultsPanel from '../components/ResultsPanel';
import ResultsFilter from '../components/ResultsFilter';

export default function StudyFinder(props) {
  if (!Array.isArray(props.studies)) return <div>No dataset was specified for the study finder.</div>;

  const [topic, setTopic] = useState(null);
  const [place, setPlace] = useState('');
  const [range, setRange] = useState('0');
  const [trials, setTrials] = useState(props.studies);

  function resetTopic(topic) { setTopic(topic); }

  function resetPlace(location) { setPlace(location); }

  function resetRange(proximity) { setRange(proximity); }

  function resetData(dataset) { setTrials(dataset); }

  function resetAll() {
    setTopic('');
    setPlace('');
    setRange('0');
  }

  return (
    <div id="study-finder">
      <ResultsFilter theme="col-25 p-8"
                     conditions={props.conditions}
                     studies={props.studies}
                     btnlabel="Reset Search"
                     onReset={resetAll}
                     onFilter={resetData}
                     onPlaceChange={resetPlace}
                     onRangeChange={resetRange}
                     onTopicChange={resetTopic} />
      <ResultsPanel theme="col-75 p-8" topic={topic} studies={trials} refloc={place} range={range} />
    </div>
  );
};

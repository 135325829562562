/**
 * Render page where studies may be searched.
 */

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/PageLayout';
import PageHead from '../components/PageHead';
import StudyFinder from '../components/StudyFinder';
import { setLinks } from '../util/pagetools';
import { findDistinctInField } from '../util/datatools';
import { localizer } from '../util/localizer';

export default function EnrollingPage(props) {
  const ctx = props.pageContext;
  const locfn = localizer(ctx);
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;

  const toplinks = setLinks(sitedata.pagelinks);
  const sitename = sitedata.sitename;
  const siteurl = sitedata.siteurl;
  const footer = { ...sitedata.footer, toplinks, sitename, siteurl };

  const studies = props.data.allStudies.edges.map(edge => edge.node);
  const topics = findDistinctInField(studies, 'condition');
  const conditions = props.data.conditions.edges[0].node.conditions.filter(issue => topics.includes(issue.id));

  return (
    <Layout sitename={sitename} links={toplinks} footer={footer} locfn={locfn}>
      <PageHead lang={ctx.locale} sitename={sitename} siteurl={siteurl} meta={sitedata.metaDescription} />
      <StudyFinder studies={studies} conditions={conditions} />
    </Layout>
  );
};

export const query = graphql`
  query siteEnrollingQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      sitename
      siteurl
      metaDescription
      pagelinks {
        title
        linkto
      }
      footer {
        theme
        text
        links {
          label
          to
          newtab
        }
      }
      errorpage {
        header
        subheader
      }
    }
    allStudies: allStudyYaml {
      edges {
        node {
          studyid
          name
          url
          about
          ageGroup
          condition
          phase
          locations {
            name
            street
            city
            state
            zip
            lat
            lng
          }
        }
      }
    }
    conditions: allDataYaml {
      edges {
        node {
          conditions {
            id
            name
          }
        }
      }
    }
  }`;

/**
 * A custom hook which integrates the life cycle of a dataset search filter.
 */

import { useState, useEffect } from 'react';

export default function useDatafilter(fieldlist) {
  const [filters, setFilters] = useState({
    ageGroup: [],
    phase: []
  });

  /**
   * On selection an object, {name, selected}, will be added to a filter field's array.
   * @param boolean - is selected
   * @param string - field name, one of original ones passed in constructor
   * @param string - a field value
   */
  function onPick(checked, field, value) {
    const current = filters[field];
    let setting = current.find(item => item.name===value);
    // console.log(`setting-> ${(setting ? 'exists':'new')} ->`, setting);
    if (setting) setting.selected=checked;
    else {
      setting = { name: value, selected: checked };
      current.push(setting);
      // console.log('SETTING->', setting);
    }
  }

  /**
   * Iterate over filters and return an array of values for each field in the filter.
   * @return object
   */
  function getCriteria() {
    return Object.keys(filters).reduce((selected, field) => {
      const picked = filters[field].filter(obj => obj.selected).map(obj => obj.name);
      if (picked.length>0) selected[field] = picked;
      return selected;
      }, {});
  }

  function clear() { setFilters({ageGroup: [], phase: []}); }

  function isEmpty() { return Object.keys(getCriteria()).length===0; }

  function getFields() { return ['ageGroup', 'phase']; }

  return {onPick, getCriteria, getFields, clear, isEmpty};
};

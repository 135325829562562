/**
 * Render a form by which a dataset can be filtered.
 * Supported props: theme btnlabel conditions studies onFilter onTopicChange onPlaceChange onRangeChange onReset
 */
import React, { useState } from 'react';
import Dropdown from './Dropdown';
import PlaceField from './PlaceField';
import ConditionField from './ConditionField';
import DatasetFilter from './DatasetFilter';
import { css } from '../util/pagetools';

export default function ResultsFilter(props) {
  const rootAttribs = {id: 'results-filter'};
  if (props.theme) rootAttribs.className = props.theme;
  const conditions = props.conditions || [];
  const btnlabel = props.btnlabel || 'Reset';

  const [condition, setCondition] = useState('');
  const [place, setPlace] = useState('');
  const [distance, setDistance] = useState('0');

  function onConditionSelected(choice) {
    const newvalue = (choice ? choice.value : null);
    setCondition(newvalue);
    if (props.onTopicChange) props.onTopicChange(choice);
  }

  function placeChange(address) {
    let newvalue = null;
    if (address) {
      setPlace(address.full);
      const {full, city, state, lat, lng} = address;
      newvalue = {full, city, state, lat, lng};
    }
    if (props.onPlaceChange) props.onPlaceChange(newvalue);
  }

  function distanceChange(e) {
    setDistance(e.target.value);
    if (props.onRangeChange) props.onRangeChange(e.target.value);
  }

  function resetFields() {
    setDistance('0');
    setPlace('');
    setCondition('');
    if (props.onReset) props.onReset();
  }

  const distanceOptions = [
    {id:'0', value:'Entire World'},
    {id:'10', value:'10 mi (about 15km)'},
    {id:'25', value:'25 mi (about 40km)'},
    {id:'50', value:'50 mi (about 80km)'},
    {id:'100', value:'100 mi (about 160km)'},
    {id:'250', value:'250 mi (about 400km)'},
  ];

  return (
    <div {...rootAttribs}>
      <div className="bg-ltgray p-8">
        <div className="form-group">
          <ConditionField name="medcondition" value={condition} dataset={conditions} onChange={onConditionSelected} />
        </div>
        <div className="form-group">
          <PlaceField name="clinicloc" value={place} onChange={placeChange} theme="h-40" />
        </div>
        <div className={css('form-group', (place ? null:'is-disabled'))}>
          <label htmlFor="range">Within</label>
          <br />
          <Dropdown name="distance" value={distance} items={distanceOptions} onChangeBlur={distanceChange} />
        </div>
        <div className="form-group flx">
          <button className="noborder btn-white-on-light" onClick={resetFields}>{btnlabel}</button>
        </div>
        <hr />
        <DatasetFilter topic={condition} dataset={props.studies} onFilter={props.onFilter} theme="mb-20" />
      </div>
    </div>
  );
};

/**
 * Helper functions for transforming data.
 */

/**
 * Record the distinct values for each of a set of fields among a dataset.
 * @param array - the dataset
 * @param array - a list of field names
 * @return object - of type {[field]: {values, counts}}
 */
function findDistinct(dataset, fields) {
  const stats = dataset.reduce((report, study) => {
    fields.reduce((features, field) => {
      if (!report[field]) report[field]={values:[], counts: {}};
      const value = study[field];
      if (value) {
        if (report[field].values.includes(value)) report[field].counts[value] += 1;
        else {
          report[field].values.push(value);
          report[field].counts[value] = 1;
        }
      }
      return 0;
    }, 0);
    return report;
  }, {});
  return Object.keys(stats).reduce((arr, field) => {
    arr.push({name: field, values: stats[field].values, counts: stats[field].counts})
    return arr;
    }, []);
}

/**
 * Record the distinct values for a field among a dataset.
 * @param array - the dataset
 * @param string - a field whose values are a collection
 * @return array
 */
function findDistinctInField(dataset, field) {
  return dataset.reduce((report, study) => {
    study[field].forEach(val => { if (!report.includes(val)) report.push(val); });
    return report;
  }, []);
}

/**
 * Filter a dataset by an object expressing the criteria.
 * @param array - an array of objects, the data
 * @param object - an object whose keys represent fields; ex. {phase: ['Abc', 'Xyz']}
 * @param string - a medical condition
 * @return array
 */
function filterData(dataset, criteria, topic) {
  // console.log(`>>filt ${dataset.length} rows by ${isEmpty(criteria)?'Any':JSON.stringify(criteria)}, topic=${topic||'All'}`);
  const subset = (topic ? dataset.filter((record, recordId) => record.condition.includes(topic)) : dataset);
  if (isEmpty(criteria)) return subset;
  return subset.filter((record, recordId) => {
    return Object.keys(criteria).reduce((result, field) => {
      return result && criteria[field].includes(record[field]);
      }, true);
  });
}

function isEmpty(obj) { return !Boolean(obj) || Object.keys(obj).length===0; }

/**
 * Sort a collection by a specified field.
 * @param array - the dataset
 * @param string - a field name
 * @return array
 */
function sortByField(dataset, field) {
  function fieldsort(a,b) { const A=a[field]; const B=b[field]; if (A>B) return 1; if (A<B) return -1; return 0; };
  if (!Array.isArray(dataset)) return dataset;
  else if (dataset.length===0) return dataset;
  else if (!dataset[0][field]) return dataset;
  return dataset.sort(fieldsort);;
}

export {findDistinct, findDistinctInField, filterData, sortByField};

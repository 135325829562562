/**
 * Render a google places autocomplete field.
 * Supported props: name value format countries theme onChange onValidate
 */
import React, { useRef, useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { css } from '../util/pagetools';
import extractAddressFields from '../util/placetools';

export default function PlaceField(props) {
  const fieldName = (props.name || 'place_autocomplete');
  const COUNTRY_LOOKUPS = (Array.isArray(props.countries) ? props.countries : ['us']);
  const settings = { componentRestrictions: { country: COUNTRY_LOOKUPS }};
  if (props.format && props.format!=='cities') settings.types = [props.format];
  const fieldRef = useRef(null);

  // Runs onChange and onBlur for address field
  function validatePlaceInput(event) { }

  function onPlaceChange(place) {
    if (props.onChange) props.onChange((place.geometry ? extractAddressFields(place) : null));
  }

  const compOptions = {
    name: fieldName,
    id: fieldName,
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    options: settings,
    onPlaceSelected: onPlaceChange,
    ref: fieldRef,
    className: css('form-control', props.theme),
    defaultValue: props.value,
    placeholder: 'Enter a city',
    'aria-required': true
  };
  if (props.onValidate) {
    compOptions.onChange = props.onValidate;
    compOptions.onBlur = props.onValidate;
  }

  useEffect(() => {
    // Needed to actually clear field
    const elem = document.getElementById(props.name);
    if (elem && !props.value) elem.value = '';
  });

  return <Autocomplete {...compOptions} />;
};

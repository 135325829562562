/**
 * Render a single clinical study.
 * Supported props: config refloc
 */
import React from 'react';
import { css } from '../util/pagetools';
import locSorter from '../util/geotools';
import Glyph from './Glyph';

export default function Study(props) {
  if (!props.config) return <div>No study</div>;
  const s = props.config;
  const filters = [
    {id: 'ageGroup', icon: 'people'},
    {id: 'phase', icon: 'chart'}
  ];
  s.features = filters.reduce((features, field) => {
    if (s[field.id]) features.push(field)
    return features;
  }, []);
  const closest = locSorter(props.refloc, s.locations)[0];
  const linkto = s.linkto || s.url;

  return (
    <div className="study">
      <div className="title"><a href={linkto} target="_blank">{s.name}</a></div>
      <div className="stats">
        {s.features.map((item, index) => (
          <React.Fragment key={`f-${index}`}>
            <Glyph type={item.icon} /><span className="stat">{s[item.id]}</span>
          </React.Fragment>
        ))}
      </div>
      <div className="descrip">{s.about}</div>
      <div className={css('flx distance', (props.refloc ? 'vis':'hid'))}>
        <div>Closest Location: <strong>{closest.dist} mi</strong></div>
        <div className="pl-20">({closest.name} - {closest.city}, {closest.state})</div>
      </div>
      <div className="foot"><a href={linkto} target="_blank">Learn More</a></div>
    </div>
  );
};
